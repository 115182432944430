import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelArticle } from 'models/modelArticle'
import { ModelBoxes } from '../../models/modelBoxes'

const initialState = {
  data: [],
  totalItems: 0,
  totalPages: 0,
  loading: false,
  serverStatistic: {
    activated: 0,
    active: 0,
    inactive: 0,
    locked: 0,
  },
}

export class articleReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getServerStatistic({}) {
    this.draftState.loading = true
  }

  getServerStatisticSuccess(payload) {
    this.draftState = {
      ...this.draftState,
      serverStatistic: payload,
    }
  }

  getServerStatisticError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  getArticles({}) {
    this.draftState.loading = true
  }

  getArticlesSuccess({ data, totalPages, totalItems }) {
    this.draftState = {
      ...this.draftState,
      loading: false,
      totalPages,
      totalItems,
      data: data.map(ModelArticle),
    }
  }

  getArticlesError(error) {
    this.draftState.loading = false
    this.draftState.error = error
  }

  changeArticle({}) {
    this.draftState.loading = true
  }
  changeArticleSuccess() {
    this.draftState.loading = false
  }
  changeArticleError() {
    this.draftState.loading = false
  }
}

export const article = createReducerFunction(articleReducer, initialState)
const articleActions = createActionCreators(articleReducer)
export default articleActions
