const ENDPOINT = {
  authorize: '/sanctum/token',
  logout: '/sanctum/logout',

  getArticles: '/get-articles',
  addArticle: '/add-article',
  editArticle: '/edit-article',
  deleteArticle: '/delete-article',

  getBannedIps: '/get-banned-ips',
  addBannedIp: '/add-banned-ip',
  unbanIp: '/unban-ip',
  getBannedBoxes: '/get-banned-boxes',
  getBannedUsers: '/get-banned-users',

  getLogs: '/get-box-log',

  getServerStatistic: '/get-boxes-stats',

  getBoxes: '/get-boxes',
  addBox: '/add-box',
  editBox: '/edit-box',
  addMultipleBoxes: '/add-boxes',

  getUsers: '/get-users',
  addUser: '/add-user',
  suspendUser: '/suspend-user-by-id',
  changeUserEmail: '/change-user-email-by-id',
  changeUserPassword: '/change-user-password-by-id',
  changeUserStatusById: '/change-user-status-by-id',
  unbanUser: '/unban-user-by-id',

  getUserProfileData: '/get-user-profile-data',
  changeProfileAvatar: '/change-profile-avatar',
  changeProfilePassword: '/change-user-pw-profile',
  getUsersCreditsList: '/get-users-credits-list',

  getCreditsLogs: '/get-credits-logs',

  getApiKeys: '/get-api-keys',
  addApiKey: '/add-api-key',
  updateApiKey: '/update-api-key',
  deleteApiKey: '/delete-api-key',
}

export default ENDPOINT
