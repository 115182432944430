import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelBoxes } from '../../models/modelBoxes'

const initialState = {
  data: [],
  totalItems: 0,
  totalPages: 0,
  loading: false,
  logs: [],
}

export class boxesReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getBoxes({}) {
    this.draftState.loading = true
  }

  getBoxesSuccess(payload) {
    this.draftState = {
      ...this.draftState,
      loading: false,
      totalItems: payload?.totalItems,
      totalPages: payload?.totalPages,
      data: payload?.data?.map(box => ModelBoxes(box)),
    }
  }

  getBoxesError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  getLogs({}) {
    this.draftState.loading = true
  }

  getLogsSuccess({ logs = [] }) {
    this.draftState = {
      ...this.draftState,
      loading: false,
      logs,
    }
  }

  getLogsError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  addBox({}) {
    this.draftState.loading = true
  }
  addBoxSuccess(payload) {
    this.draftState = {
      ...this.draftState,
      loading: false,
      //data: payload?.data?.map(box => ModelBoxes(box)),
    }
  }
  addBoxError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  updateBox({}) {
    this.draftState.loading = true
  }
  updateBoxSuccess(payload) {
    this.draftState = {
      ...this.draftState,
      //...payload,
      loading: false,
      //data: payload?.data?.map(box => ModelBoxes(box)),
    }
  }
  updateBoxError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  addMultipleBoxes({}) {
    this.draftState.loading = true
  }

  addMultipleBoxesSuccess(payload) {
    this.draftState = {
      ...this.draftState,
      loading: false,
    }
  }

  addMultipleBoxesError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }
}

export const boxes = createReducerFunction(boxesReducer, initialState)
const boxesActions = createActionCreators(boxesReducer)
export default boxesActions
