import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import store from 'store'

const initialState = () => {
  const user = store.get('user')
  if (user) {
    return { ...user, authorized: true, loading: false }
  }
  return {
    id: '',
    name: '',
    role: '',
    profile_photo: '',
    authorized: false,
    loading: false,
  }
}

export class userReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  login({}) {
    this.draftState = {
      ...this.draftState,
      loading: true,
    }
  }

  loginSuccess(data) {
    this.draftState = {
      loading: false,
      authorized: true,
      ...data,
    }
  }

  loginError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  logout() {
    this.draftState = {
      authorized: false,
    }
  }
}

export const user = createReducerFunction(userReducer, initialState())
export const userActions = createActionCreators(userReducer)
export default userActions
