import { format } from 'utils/date'
import _get from 'lodash/get'

export function ModelUsers(data = {}) {
  return {
    ban_reason: data.ban_reason,
    status: data.status,
    banned_by: data.banned_by,
    banned_date: data.banned_date,
    created_at: format(data.created_at?.replace('[UTC]', '')),
    creator_id: data.creator_id,
    credits_dev_act: data.credits_dev_act,
    credits_std_act: data.credits_std_act,
    email: data.email,
    email_verified_at: data.email_verified_at,
    failed_login_attempts: data.failed_login_attempts,
    key: data.id,
    last_login: data.last_login,
    last_login_ip: data.lastLoginIp || '-',
    login: data.login,
    name: data.name,
    profile_photo: data.profile_photo,
    role: data.role,
  }
}
