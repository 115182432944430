import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  // Users
  {
    path: '/users',
    Component: lazy(() => import('pages/users')),
    exact: true,
  },
  {
    path: '/users/:sub',
    Component: lazy(() => import('pages/users')),
    exact: true,
  },
  // Boxes
  {
    path: '/boxes',
    Component: lazy(() => import('pages/boxes')),
    exact: true,
  },
  // Banned
  {
    path: '/banned/ip',
    Component: lazy(() => import('pages/banned/ip')),
    exact: true,
  },
  // Logs
  {
    path: '/logs',
    Component: lazy(() => import('pages/logs/credits')),
    exact: true,
  },
  // Api Keys
  {
    path: '/api-keys',
    Component: lazy(() => import('pages/api-keys')),
    exact: true,
  },
  // Stats
  {
    path: '/stats',
    Component: lazy(() => import('pages/stats')),
    exact: true,
  },
  // Profile
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact, ...rest }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component {...rest} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
