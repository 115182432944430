import { takeLatest, put, call } from 'redux-saga/effects'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'

export function* getServerStatistic() {
  try {
    const data = yield call(api.post, endpoints.getServerStatistic)

    yield put(Actions.article.getServerStatisticSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.article.getServerStatisticError({ error }))
  }
}
export function* getArticles({ payload = {} }) {
  const { page = 0, searchQuery = '' } = payload
  try {
    const data = yield call(api.post, endpoints.getArticles, {
      data: { page, searchQuery },
    })

    yield put(Actions.article.getArticlesSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.article.getArticlesError({ error }))
  }
}

export function* changeArticle({ payload = {} }) {
  const { endpoint, ...rest } = payload
  try {
    const data = yield call(api.post, endpoint, {
      data: { ...rest },
    })
    yield getArticles({})
    yield put(Actions.article.changeArticleSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.article.changeArticleError({ error }))
  }
}

export default function* bannedSaga() {
  yield takeLatest(Actions.article.getServerStatistic.type, getServerStatistic)
  yield takeLatest(Actions.article.getArticles.type, getArticles)
  yield takeLatest(Actions.article.changeArticle.type, changeArticle)
}
