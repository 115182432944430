import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import { CANCEL, END } from 'redux-saga'
const apiUrl = process.env.REACT_APP_API
const dev = process.env.NODE_ENV !== 'production'

class DataError extends Error {
  constructor(status, statusText, data) {
    super(`${status} ${statusText}`)
    Object.setPrototypeOf(this, { ...DataError.prototype, data, status, statusText })
  }
}

const METHOD = {
  DELETE: 'delete',
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
}

const defaultOptions = {
  data: undefined,
  eventEmitter: undefined,
  multipart: false,
  params: undefined,
  transform: undefined,
}

const getHeaders = (multipart = false) => ({
  'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
})

const multipartTransform = data =>
  Object.keys(data)
    .filter(key => Boolean(data[key]))
    .reduce((form, key) => {
      form.append(key, data[key])
      return form
    }, new FormData())

const api = (method, url, options = defaultOptions) => {
  const source = axios.CancelToken.source()
  const baseURL = dev ? '/newapi' : apiUrl
  const { multipart, data, params, eventEmitter, transform } = options

  const promise = axios({
    baseURL,
    cancelToken: source.token,
    data,
    headers: getHeaders(multipart),
    method,
    params,
    url,
    ...(multipart && { transformRequest: [dataMultipart => multipartTransform(dataMultipart)] }),
    ...(transform && { transformResponse: [dataTransform => transform(dataTransform)] }),
    ...(eventEmitter && { onDownloadProgress: eventEmitter, onUploadProgress: eventEmitter }),
  })
    .then(response => {
      eventEmitter && eventEmitter(END)
      return response.data ? response.data : response
    })
    .catch(err => {
      eventEmitter && eventEmitter(END)
      if (err && err.response) {
        notification.error({
          message: 'Error',
          description: `Error: ${err.response.data.error}`,
        })
        throw new DataError(err.response.status, err.response.statusText, err.response.data)
      }
    })

  promise[CANCEL] = source.cancel

  return promise
}

axios.interceptors.request.use(request => {
  const { token } = store.get('user') || {}
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

axios.interceptors.response.use(undefined, error => {
  // Errors handling
  const { statusText, status, data } = error?.response || {}

  if (status === 401 && statusText === 'Unauthorized') {
    console.error('Unauthorized')
    localStorage.clear()
    window.location.href = '/#/auth/login'
    return Promise.reject(error)
  }

  if (data) {
    notification.warning({
      message: typeof data?.error === 'object' ? 'Error' : data?.error,
    })
  }
})

export default {
  [METHOD.GET]: (url, options) => api(METHOD.GET, url, options),
  [METHOD.POST]: (url, options) => api(METHOD.POST, url, options),
  [METHOD.DELETE]: (url, options) => api(METHOD.DELETE, url, options),
  [METHOD.PATCH]: (url, options) => api(METHOD.PATCH, url, options),
  [METHOD.PUT]: (url, options) => api(METHOD.PUT, url, options),
}
