import user from './user/reducers'
import settings from './settings/reducers'
import menu from './menu/reducers'
import users from './users/reducers'
import boxes from './boxes/reducers'
import banned from './banned/reducers'
import logs from './logs/reducers'
import apiKeys from './api-keys/reducers'
import article from './article/reducers'
import profile from './profile/reducers'

export default {
  article,
  apiKeys,
  banned,
  boxes,
  logs,
  menu,
  user,
  users,
  settings,
  profile,
}
