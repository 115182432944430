import { takeLatest, put, call } from 'redux-saga/effects'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'

export function* getApiKeys({ payload = {} }) {
  const { page = 0, searchQuery = '' } = payload
  try {
    const data = yield call(api.post, endpoints.getApiKeys, {
      data: { page, searchQuery },
    })

    yield put(Actions.apiKeys.getApiKeysSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.apiKeys.getApiKeysError({ error }))
  }
}

export function* changeApiKey({ payload = {} }) {
  const { endpoint, ...rest } = payload
  try {
    yield call(api.post, endpoint, {
      data: { ...rest },
    })
    yield getApiKeys({})
    yield put(Actions.apiKeys.addApiKeySuccess())
  } catch (error) {
    yield put(Actions.apiKeys.getApiKeysError({ error }))
  }
}

export default function* bannedSaga() {
  yield takeLatest(Actions.apiKeys.getApiKeys.type, getApiKeys)
  yield takeLatest(Actions.apiKeys.changeApiKey.type, changeApiKey)
}
