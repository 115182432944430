import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelUsers } from 'models/modelUsers'

const initialState = {
  data: [],
  totalItems: 0,
  totalPages: 0,
  loading: false,
}

export class usersReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getUsers({}) {
    this.draftState.loading = true
  }

  getUsersSuccess({ data, totalPages, totalItems }) {
    this.draftState = {
      loading: false,
      totalPages,
      totalItems,
      data: data.map(ModelUsers),
    }
  }

  getUsersError(error) {
    this.draftState.loading = false
    this.draftState.error = error
  }

  addUser({}) {
    this.draftState.loading = true
  }
  addUserSuccess() {
    this.draftState.loading = false
  }
  addUserError() {
    this.draftState.loading = false
  }

  changeUser({}) {
    this.draftState.loading = true
  }
  changeUserSuccess() {
    this.draftState.loading = false
  }
  changeUserError() {
    this.draftState.loading = false
  }
}

export const users = createReducerFunction(usersReducer, initialState)
const usersActions = createActionCreators(usersReducer)
export default usersActions
