import { format } from 'utils/date'

export function ModelArticle(data = {}) {
  return {
    content: data.content,
    created_at: format(data.created_at?.replace('[UTC]', '')),
    creator_id: data.creator_id,
    id: data.id,
    title: data.title,
    updated_at: format(data.updated_at?.replace('[UTC]', '')),
  }
}
