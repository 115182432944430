export const USER_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  RESELLER: 'Reseller',
  SUB_RESELLER: 'Sub Reseller',
}

export const ROLE_FILTER = {
  ALL: 'all',
  ADMINS: 'admins',
  RESELLERS: 'resellers',
}

export const STATUS_API_KEY = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const STATUS_USER = [
  { label: 'Active', value: 0 },
  { label: 'Banned', value: 1 },
  { label: 'Suspended', value: 3 },
  { label: 'Not Active', value: 4 },
]

export const STATUS_BOX = [
  { label: 'All', value: '' },
  { label: 'Activated', value: 'activated' },
  { label: 'Burned', value: 'active' },
  { label: 'Not Active', value: 'inactive' },
  { label: 'Locked', value: 'locked' },
]
// int ACTIVE = 0;
// int BANNED = 1;
// int SUSPENDED = 3;
// int NOT_ACTIVE = 4;

export default USER_ROLES
