import store from 'store'
import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    authProvider: 'jwt', // firebase, jwt
    logo: "Pandora's Box",
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    test: 'test',
    isMobileView: global.window.innerWidth < 768,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left', // left, top, nomenu
    routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    menuColor: 'white', // white, dark, gray
    theme: 'default', // default, dark
    authPagesColor: 'white', // white, gray, image
    primaryColor: '#4b7cf3',
    leftMenuWidth: 256,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
  }),
}

export class settingsReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  changeSettings(payload) {
    this.draftState = {
      ...this.draftState,
      ...payload,
    }
  }
}

export const settings = createReducerFunction(settingsReducer, initialState)
export const settingsActions = createActionCreators(settingsReducer)
export default settingsActions
