import { takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'

export function* getProfile() {
  try {
    const data = yield call(api.post, endpoints.getUserProfileData)

    yield put(Actions.profile.getProfileSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.profile.getProfileError({ error }))
  }
}
export function* updateAvatarProfile({ payload = {} }) {
  try {
    const data = yield call(api.post, endpoints.changeProfileAvatar, {
      data: { ...payload },
      multipart: true,
    })

    yield put(Actions.profile.updateAvatarProfileSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.profile.updateAvatarProfileError({ error }))
  }
}
export function* updatePasswordProfile({ payload = {} }) {
  try {
    const data = yield call(api.post, endpoints.changeProfilePassword, {
      data: { ...payload },
    })

    yield put(Actions.profile.updatePasswordProfileSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.profile.updatePasswordProfileError({ error }))
  }
}

export default function* rootSaga() {
  yield takeLatest(Actions.profile.getProfile.type, getProfile)
  yield takeLatest(Actions.profile.updateAvatarProfile.type, updateAvatarProfile)
  yield takeLatest(Actions.profile.updatePasswordProfile.type, updatePasswordProfile)
}
