import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelProfile } from 'models/modelProfile'

const initialState = {
  data: {},
  loading: false,
}

export class profileReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getProfile() {
    this.draftState.loading = true
  }

  getProfileSuccess({ data }) {
    this.draftState = {
      loading: false,
      data: ModelProfile(data),
    }
  }

  getProfileError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  updateAvatarProfile({}) {
    this.draftState.loading = true
  }

  updateAvatarProfileSuccess(payload) {
    this.draftState = {
      ...this.draftState,
      //...payload,
      loading: false,
    }
  }

  updateAvatarProfileError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }

  updatePasswordProfile({}) {
    this.draftState.loading = true
  }

  updatePasswordProfileSuccess() {
    this.draftState = {
      ...this.draftState,
      loading: false,
    }
  }

  updatePasswordProfileError(error) {
    this.draftState = {
      error,
      loading: false,
    }
  }
}

export const profile = createReducerFunction(profileReducer, initialState)
const profileActions = createActionCreators(profileReducer)
export default profileActions
