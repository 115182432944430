import { takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'

export function* getBoxes({ payload = {} }) {
  const {
    page = 1,
    searchQuery = '',
    status = '',
    ...arg
  } = payload
  try {
    const data = yield call(api.post, endpoints.getBoxes, {
      data: { page, searchQuery, status, ...arg },
    })

    yield put(Actions.boxes.getBoxesSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.boxes.getBoxesError({ error }))
  }
}
export function* getLogs({ payload }) {
  try {
    const data = yield call(api.post, endpoints.getLogs, {
      data: {
        boxSn: payload?.box_sn,
      },
    })

    yield put(Actions.boxes.getLogsSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.boxes.getLogsError({ error }))
  }
}
export function* addBox({ payload = {} }) {
  try {
    const data = yield call(api.post, endpoints.addBox, {
      data: { ...payload },
    })
    yield getBoxes({})
    yield put(Actions.boxes.addBoxSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.boxes.addBoxError({ error }))
  }
}
export function* updateBox({ payload = {} }) {
  try {
    const data = yield call(api.post, endpoints.editBox, {
      data: { ...payload },
    })
    yield getBoxes({})
    yield put(Actions.boxes.updateBoxSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.boxes.updateBoxError({ error }))
  }
}
export function* addMultipleBoxes({ payload = {} }) {
  try {
    const data = yield call(api.post, endpoints.addMultipleBoxes, {
      data: payload,
    })

    yield put(Actions.boxes.addMultipleBoxesSuccess({ ...data }))
  } catch (error) {
    notification.error({
      message: 'Error',
    })

    yield put(Actions.boxes.addMultipleBoxesError({ error }))
  }
}

export default function* rootSaga() {
  yield takeLatest(Actions.boxes.getLogs.type, getLogs)
  yield takeLatest(Actions.boxes.getBoxes.type, getBoxes)
  yield takeLatest(Actions.boxes.updateBox.type, updateBox)
  yield takeLatest(Actions.boxes.addBox.type, addBox)
  yield takeLatest(Actions.boxes.addMultipleBoxes.type, addMultipleBoxes)
}
