import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UserOutlined } from '@ant-design/icons'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Avatar, Badge } from 'antd'

import Actions from 'redux/actions'
import styles from './style.module.scss'

const ProfileMenu = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(({ user }) => ({ user }))
  const [count, setCount] = useState(7)
  const userAvatar = useSelector(({ user }) => user.profile_photo)

  const logout = e => {
    e.preventDefault()
    dispatch(Actions.user.logout())
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/profile">
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        {/*<Badge count={count}>*/}
        <Avatar src={userAvatar} className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        {/*</Badge>*/}
      </div>
    </Dropdown>
  )
}

export default ProfileMenu
