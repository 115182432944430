import { format } from 'utils/date'

export function ModelBanned(data = {}) {
  return {
    banned_user_id: data.banned_user_id,
    created_at: data.created_at,
    date: format(data.banned_date?.replace('[UTC]', '')),
    custom_created_by: data.custom_created_by,
    id: data.id,
    key: data.id,
    ip: data.ip,
    reason: data.reason,
    country: data.country,
    updated_at: format(data.updated_at?.replace('[UTC]', '')),
  }
}
