import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelApiKey } from 'models/modelApiKey'

const initialState = {
  data: [],
  totalItems: 0,
  totalPages: 0,
  loading: false,
}

export class apiKeysReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getApiKeys({}) {
    this.draftState.loading = true
  }

  getApiKeysSuccess({ data, totalPages, totalItems }) {
    this.draftState = {
      loading: false,
      totalPages,
      totalItems,
      data: data.map(ModelApiKey),
    }
  }

  getApiKeysError(error) {
    this.draftState.loading = false
    this.draftState.error = error
  }

  changeApiKey({}) {
    this.draftState.loading = true
  }
  changeApiKeySuccess() {
    this.draftState.loading = false
  }
  changeApiKeyError() {
    this.draftState.loading = false
  }
}

export const apiKeys = createReducerFunction(apiKeysReducer, initialState)
const apiKeysActions = createActionCreators(apiKeysReducer)
export default apiKeysActions
