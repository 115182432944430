import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { menuData } from 'services/menu'

const initialState = {
  menuData,
}

export class menuReducer extends ImmerReducer {
  setMenu(payload) {
    this.draftState.menuData = { ...menuData, ...payload }
  }
}

export const menu = createReducerFunction(menuReducer, initialState)
export const menuActions = createActionCreators(menuReducer)
export default menuActions
