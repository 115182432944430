import { format } from 'utils/date'
import { STATUS_API_KEY } from 'constants/roles'
const { ACTIVE, INACTIVE } = STATUS_API_KEY

export function ModelApiKey(data = {}) {
  return {
    active: data.active,
    status: data.active ? ACTIVE : INACTIVE,
    api_key: data.apiKey,
    name: data.name || 'Some name',
    created_at: format(data.created_at?.replace('[UTC]', '')),
    description: data.description || '-',
    creator_id: data.creator_id,
    id: data.id,
    key: data.id,
    updated_at: format(data.updated_at?.replace('[UTC]', '')),
    uses_num: data.uses_num,
  }
}
