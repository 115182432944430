import { takeLatest, put, call } from 'redux-saga/effects'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'
import { notification } from 'antd'

const getMessage = type => {
  let result
  switch (type) {
    case '/change-user-status-by-id':
      result = 'Status changed successfully!'
      break
    case '/change-user-password-by-id':
      result = 'Password changed successfully!'
      break
    case '/change-user-email-by-id':
      result = 'Email changed successfully!'
      break
    default:
      result = 'Successfully!'
      break
  }
  return result
}

export function* getUsers({ payload = {} }) {
  const { page = 0, roleFilter = 'all', searchQuery = '', subResellerMode = false, ...rest } = payload
  try {
    const data = yield call(api.post, endpoints.getUsers, {
      data: { page, roleFilter, searchQuery, subResellerMode, ...rest },
    })

    yield put(Actions.users.getUsersSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.users.getUsersError({ error }))
  }
}

export function* addUser({ payload = {} }) {
  try {
    yield call(api.post, endpoints.addUser, { data: { ...payload } })
    yield getUsers({})
    yield put(Actions.users.addUserSuccess())
  } catch (error) {
    yield put(Actions.users.addUserError({ error }))
  }
}

export function* changeUser({ payload = {} }) {
  const { endpoint, ...rest } = payload
  try {
    yield call(api.post, endpoint, { data: { ...rest } })
    yield getUsers({})
    yield put(Actions.users.addUserSuccess())
    notification.success({
      message: 'Success!',
      description: getMessage(endpoint),
    })
  } catch (error) {
    yield put(Actions.users.addUserError({ error }))
  }
}

export default function* rootSaga() {
  yield takeLatest(Actions.users.getUsers.type, getUsers)
  yield takeLatest(Actions.users.addUser.type, addUser)
  yield takeLatest(Actions.users.changeUser.type, changeUser)
}
