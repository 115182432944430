import { all, fork } from 'redux-saga/effects'
import user from './user/sagas'
import users from './users/sagas'
import boxes from './boxes/sagas'
import banned from './banned/sagas'
import logs from './logs/sagas'
import apiKeys from './api-keys/sagas'
import article from './article/sagas'
import profile from './profile/sagas'

export default function* rootSaga() {
  yield all([
    fork(user),
    fork(users),
    fork(boxes),
    fork(banned),
    fork(logs),
    fork(apiKeys),
    fork(article),
    fork(profile),
  ])
}
