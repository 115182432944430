import { all, takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import * as jwt from 'services/jwt'
import Actions from 'redux/actions'

export function* LOGIN({ payload }) {
  const { email, password, reCaptchaValue } = payload
  try {
    const data = yield call(jwt.login, {
      login: email,
      password,
      reCaptchaValue,
    })

    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })

    yield put(Actions.user.loginSuccess({ ...data }))
    yield history.push('/')
  } catch (err) {
    yield put(Actions.user.loginError({ err }))
  }
}

export function* LOGOUT() {
  try {
    yield call(jwt.logout)
  } catch {
    notification.error({
      message: 'Error',
      description: 'Unknown',
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(Actions.user.login.type, LOGIN)])
  yield all([takeLatest(Actions.user.logout.type, LOGOUT)])
}
