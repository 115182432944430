import ROLES from 'constants/roles'
const { SUPER_ADMIN, ADMIN, RESELLER } = ROLES
export const menuData = [
  {
    title: 'Dashboards',
    key: 'dashboards',
    icon: 'fe fe-home',
    url: '/dashboard',
  },
  {
    title: 'Users',
    key: 'users',
    roles: [SUPER_ADMIN, ADMIN, RESELLER],
    icon: 'fe fe-users',
    url: '/users',
  },
  {
    title: 'Boxes',
    key: 'boxes',
    icon: 'fe fe-box',
    url: '/boxes',
  },
  {
    title: 'Banned',
    key: 'banned',
    icon: 'fe fe-lock',
    roles: [SUPER_ADMIN, ADMIN],
    children: [
      {
        title: 'IP',
        key: 'bannedIp',
        url: '/banned/ip',
      },
    ],
  },
  {
    title: 'Logs',
    key: 'logs',
    icon: 'fe fe-file-text',
    url: '/logs',
    roles: [SUPER_ADMIN, ADMIN],
  },
  {
    title: 'Api Keys',
    key: 'apiKeys',
    icon: 'fe fe-git-branch',
    roles: [SUPER_ADMIN, ADMIN],
    url: '/api-keys',
  },
  {
    title: 'Stats',
    key: 'stats',
    icon: 'fe fe-bar-chart-2',
    roles: [SUPER_ADMIN, ADMIN],
    url: '/stats',
  },
]
