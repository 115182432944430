import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelBanned } from 'models/modelBanned'

const initialState = {
  data: [],
  totalItems: 0,
  totalPages: 0,
  loading: false,
}

export class bannedReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getBannedIps({}) {
    this.draftState.loading = true
  }

  getBannedIpsSuccess({ data, totalPages, totalItems }) {
    this.draftState = {
      loading: false,
      totalPages,
      totalItems,
      data: data.map(ModelBanned),
    }
  }

  getBannedIpsError(error) {
    this.draftState.loading = false
    this.draftState.error = error
  }

  addBannedIp({}) {
    this.draftState.loading = true
  }
  addBannedIpSuccess() {
    this.draftState.loading = false
  }
  addBannedIpError() {
    this.draftState.loading = false
  }

  unbanIp({}) {
    this.draftState.loading = true
  }
  unbanIpSuccess() {
    this.draftState.loading = false
  }
  unbanIpError() {
    this.draftState.loading = false
  }
}

export const banned = createReducerFunction(bannedReducer, initialState)
const bannedActions = createActionCreators(bannedReducer)
export default bannedActions
