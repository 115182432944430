import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import sagas from './sagas'

import { routerMiddleware } from 'connected-react-router'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

export function configureStore(history) {
  const sagaMiddleware = createSagaMiddleware()
  const routeMiddleware = routerMiddleware(history)
  const middlewares = [sagaMiddleware, routeMiddleware]
  const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))

  sagaMiddleware.run(sagas)

  return store
}
