import { format } from '../utils/date'

export const ModelBoxes = (obj = {}) => {
  return {
    act_1: obj?.act_1 || 0,
    act_1_date: format(obj?.act_1_date?.replace('[UTC]', '')) || '-',
    act_2: obj?.act_2 || 0,
    act_2_date: format(obj?.act_2_date?.replace('[UTC]', '')) || '-',
    act_3: obj?.act_3 || 0,
    act_3_date: format(obj?.act_3_date?.replace('[UTC]', '')) || '-',
    act_4: obj?.act_4 || 0,
    act_4_date: format(obj?.act_4_date?.replace('[UTC]', '')) || '-',
    box_sn: obj?.box_sn?.toUpperCase() || '',
    box_uid: obj?.box_uid?.toUpperCase() || '',
    card_sn: obj?.card_sn?.toUpperCase() || '-',
    change_card_count: obj?.change_card_count || 0,
    creatorId: obj?.creatorId || 0,
    id: obj?.id || 0,
    lock: obj?.lock || 0,
    lock_date: format(obj?.lock_date?.replace('[UTC]', '')) || '-',
    lock_reason: obj?.lock_reason || '-',
    locked_by: obj?.locked_by || 0,
    softStatus: obj?.softStatus || 0,
    hwRev: obj?.hwRev || '-',
  }
}
