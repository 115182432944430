export const ModelProfile = (obj = {}) => {
  return {
    activation_count: obj?.activation_count || 0,
    credits_dev: obj?.credits_dev || 0,
    credits_std: obj?.credits_std || 0,
    email: obj?.email || null,
    login: obj?.login || '',
    name: obj?.name || '',
  }
}
