import { takeLatest, put, call } from 'redux-saga/effects'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'

export function* getCreditsLogs({ payload = {} }) {
  const { page = 0, searchQuery = '', fromDate = '1019-11-26', toDate = '2020-12-26' } = payload
  try {
    const data = yield call(api.post, endpoints.getCreditsLogs, {
      data: { page, searchQuery, fromDate, toDate },
    })

    yield put(Actions.logs.getCreditsLogsSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.logs.getCreditsLogsError({ error }))
  }
}

export default function* bannedSaga() {
  yield takeLatest(Actions.logs.getCreditsLogs.type, getCreditsLogs)
}
