import moment from 'moment'

export function format(value, format = 'YYYY-MM-DD') {
  //h:mm:ss
  try {
    return value && moment(value).format(format)
  } catch (e) {
    console.error('ERROR', e)
  }
}

export function toISOS(year, month, day) {
  return new Date(Date.UTC(year, month, day)).toISOString()
}
