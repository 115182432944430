import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ModelLogs } from 'models/modelLogs'

const initialState = {
  credits: {
    data: [],
    totalItems: 0,
    totalPages: 0,
    loading: false,
    from: null,
    to: null,
  },
}

export class logsReducer extends ImmerReducer {
  // eslint-disable-next-line no-empty-pattern
  getCreditsLogs({}) {
    this.draftState.credits = {
      ...this.draftState,
      loading: true,
    }
  }

  getCreditsLogsSuccess({ data, totalPages, totalItems }) {
    this.draftState.credits = {
      loading: false,
      totalPages,
      totalItems,
      data: data.map(ModelLogs),
    }
  }

  getCreditsLogsError(error) {
    this.draftState.credits = {
      ...this.draftState,
      loading: false,
      error,
    }
  }
}

export const logs = createReducerFunction(logsReducer, initialState)
const logsActions = createActionCreators(logsReducer)
export default logsActions
