import api from 'services/axios'
import store from 'store'
import endpoints from 'constants/endpoints'

export async function login({ login, password, reCaptchaValue }) {
  const response = await api.post(endpoints.authorize, {
    data: {
      login,
      password,
      gRecaptchaResponse: reCaptchaValue,
    },
  })
  const { token } = response
  if (token) {
    store.set('user', response)
  }
  return response
}

export async function logout() {
  const response = await api.get(endpoints.logout)
  if (response.success) {
    store.remove('user')
    return true
  }
}
