import { takeLatest, put, call } from 'redux-saga/effects'
import endpoints from 'constants/endpoints'
import Actions from 'redux/actions'
import api from 'services/axios'

export function* getBannedIps({ payload = {} }) {
  const { page = 0, searchQuery = '' } = payload
  try {
    const data = yield call(api.post, endpoints.getBannedIps, {
      data: { page, searchQuery },
    })

    yield put(Actions.banned.getBannedIpsSuccess({ ...data }))
  } catch (error) {
    yield put(Actions.banned.getBannedIpsError({ error }))
  }
}

export function* addBannedIp({ payload = {} }) {
  try {
    yield call(api.post, endpoints.addBannedIp, { data: { ...payload } })
    yield getBannedIps({})
    yield put(Actions.banned.addBannedIpSuccess())
  } catch (error) {
    yield put(Actions.banned.addBannedIpError({ error }))
  }
}

export function* unbanIp({ payload = {} }) {
  try {
    yield call(api.post, endpoints.unbanIp, { data: { ...payload } })
    yield getBannedIps({})
    yield put(Actions.banned.unbanIpSuccess())
  } catch (error) {
    yield put(Actions.banned.unbanIpError({ error }))
  }
}

export default function* bannedSaga() {
  yield takeLatest(Actions.banned.getBannedIps.type, getBannedIps)
  yield takeLatest(Actions.banned.addBannedIp.type, addBannedIp)
  yield takeLatest(Actions.banned.unbanIp.type, unbanIp)
}
