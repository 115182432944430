import { format } from 'utils/date'

export function ModelLogs(data = {}) {
  return {
    amount: data.amount,
    assigned_by: data.assigned_by,
    assigned_to: data.assigned_to,
    created_at: data.created_at,
    date: format(data.created_at?.replace('[UTC]', '')),
    creator: `${data.creator.login} / ${data.creator.name}`,
    credit_type: typeof data.credit_type === 'string' ? data.credit_type.toUpperCase() : '',
    id: data.id,
    key: data.id,
    recipient: `${data.recipient.login} / ${data.recipient.name}`,
    transaction_type: data.transaction_type,
    updated_at: data.updated_at,
  }
}
